import React from 'react';
import TsParticles from "react-tsparticles";
import { loadFull } from "tsparticles";

export const Particles = React.memo(() => {
  return (
    <TsParticles
      id="tsparticles"
      init={(main) => loadFull(main)}
      options={{
        fullScreen: {
          zIndex: -1,
        },
        particles: {
          wobble: {
            enable: true,
            speed: 1,
            distance: { min: 1, max: 5 }
          },
          color: {
            value: "#fff6ce",
          },
          move: {
            direction: 'top',
            enable: true,
            outModes: { default: 'out' },
            random: true,
            speed: 1.5,
          },
          number: {
            density: { enable: true },
            value: 100,
          },
          opacity: {
            random: true,
            value: {
              min: 0.1,
              max: 1,
            },
            animation: {
              enable: true,
              speed: 0.5,
              minimumValue: 0,
            },
          },
          size: {
            random: true,
            value: {
              min: 0,
              max: 1.5,
            },
            animation: {
              speed: 1,
              minimumValue: 0.5,
            },
          },
        },
      }}
    />
  );
});
