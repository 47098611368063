import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { LandingPage } from './landing/LandingPage';
import { Background } from './background/Background';
import { FaqPage } from './faq/FaqPage';

const MapRedirect = () => {
    useEffect(() => {
        window.location.replace(process.env.REACT_APP_MAP_URL);
    }, []);

    return <></>;
}

export const AppRouter = () => {
    const location = useLocation();
    useEffect(() => window.scroll(0, 0), [location]);

    return <>
        <Switch>
            <Route exact path='/' component={LandingPage} />
            <Route path='/faq' component={FaqPage} />
            <Route path='/map' component={MapRedirect} />
        </Switch>
        <Background/>
    </>;
};
