import React from 'react'
import { Particles } from './Particles'
import './Background.css'

import image0 from './images/0.jpg'
import image3000 from './images/3000.jpg'
import image6000 from './images/6000.jpg'
import image9000 from './images/9000.jpg'
import image12000 from './images/12000.jpg'
import image15000 from './images/15000.jpg'
import image18000 from './images/18000.jpg'
import image21000 from './images/21000.jpg'

const images = [
    { time: 0,     src: image0 },
    { time: 3000,  src: image3000 },
    { time: 6000,  src: image6000 },
    { time: 9000,  src: image9000 },
    { time: 12000, src: image12000 },
    { time: 15000, src: image15000 },
    { time: 18000, src: image18000 },
    { time: 21000, src: image21000 },
]

const dayInSeconds = 24 * 60 * 60
const offsetInSeconds = 6 * 60 * 60


function toMinecraftTimeInTicks(realDate) {
    const realTimeInSeconds = realDate.getHours() * 60 * 60
    const offsettedTimeInSeconds = (dayInSeconds + realTimeInSeconds - offsetInSeconds) % dayInSeconds

    return (offsettedTimeInSeconds / dayInSeconds) * 24000
}

function getClosestImageSrc(timeInTicks) {
    let minTickDiff = Number.POSITIVE_INFINITY
    let minSrc = null

    for (const image of images) {
        const tickDiff = Math.abs(timeInTicks - image.time)
        
        if (tickDiff < minTickDiff) {
            minTickDiff = tickDiff
            minSrc = image.src
        }
    }

    return minSrc
}

export const Background = React.memo(() => {
    return <>
        <Particles />
        <img className="background"
            src={getClosestImageSrc(toMinecraftTimeInTicks(new Date()))}
            alt="background"></img>
    </>
})
